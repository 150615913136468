import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import ImageTextContainer from '../../../../components/ImageTextContainer'
import ImageText from '../../../../components/ImageTextContainer/ImageText'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'
import Button from '../../../../components/Button'
import Img from '../../../../components/Img'

import TrainingBusinessCoachingDoc from '../../../../assets/documents/Training/Brochure-formation_Coaching-en-entreprise.pdf'

function TrainingLifeCoachingFirstModule() {
	const pageId = 37

	return (
		<div className="SOUKIASSIAN-TrainingLifeCoachingFirstModule">
			<Header
				type="finalPage"
				title="MAÎTRISEZ LA COMMUNICATION NON BLESSANTE"
			/>

			<ImageTextContainer>
				<ImageText
					pageId={pageId}
					entityId={1}
					flippedImage
					text={
						<>
							<span>
								L'école L.C.C. © propose une formation
								innovante dédiée à l'Écoute et l'Expression Non
								Blessantes.
							</span>
							<span>
								Vous{' '}
								<span className="underline">
									apprenez à vous relationner dans la
									bienveillance
								</span>{' '}
								avec une{' '}
								<span className="underline">
									communication affirmative humaine.
								</span>
							</span>
							<span>
								Ouverte à tous, la formation vous donne{' '}
								<span className="underline">
									les solutions pour libérer les accumulations
									de conflits et de stress.
								</span>
							</span>
							<span className="underline">
								Transformez votre parcours professionnel en un
								développement proactif enrichissant.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={2}
					text={
						<>
							<span>
								La formation comprend un{' '}
								<b>concept clé en communication.</b>
							</span>
							<span>
								<b>Ce concept E.E.N.B.</b> © apprend l'<b>É</b>
								coute et l'<b>E</b>xpression <b>N</b>on <b>B</b>
								lessantes des états conflictuels inconscients
								présents dans une interaction relationnelle.
							</span>
							<span>
								Cette communication libère les pressions
								inconscientes pour des relations plus agréables
								et un travail plus efficient.
							</span>
						</>
					}
					left
				/>
				<ImageText
					pageId={pageId}
					entityId={3}
					flippedImage
					text={
						<>
							<span>
								Les journées de notre formation comprennent une
								pédagogie intuitive en communication verbale et
								non verbale.
							</span>
							<span>
								Nos cours, 30% théorique et 70% pratique,
								offrent une expérience d'apprentissage
								enrichissante et complète.
							</span>
							<span>
								Les participants{' '}
								<span className="underline">
									développent des compétences en communication
									pour des relations plus sereines.
								</span>
							</span>
						</>
					}
				/>
				<ImageText
					text={
						<>
							<span>
								<b>Formation en groupe</b>
								<br />3 jours non-consécutifs.
							</span>
							<span>
								<b>Formation en individuel</b>
								<br />3 demi-journées non-consécutives.
							</span>
							<span className="textCenter">
								<hr />
								<Img
									style={{ width: '85%', maxWidth: '300px' }}
									pageId={pageId}
									entityId={4}
									noInfoData
								/>
								<br />
								<Button
									target="_blank"
									link={TrainingBusinessCoachingDoc}
								>
									Télécharger le programme
								</Button>
								<br />
								<Button link="/contact">
									Demandez un entretien
								</Button>
							</span>
						</>
					}
					left
				/>
			</ImageTextContainer>

			<BannerTestimonie
				pageId={pageId}
				entityId={5}
				name="Josiane"
				commentary={
					<>
						Enfin une méthode efficace en communication non
						blessante. Je peux dorénavant communiquer plus
						sereinement sans blesser et sans être blessée.
						<br />
						<br />
						Ma vie est apaisée, je gère beaucoup mieux mes émotions,
						mes pressions et mon stress. Je m'affirme en confiance
						dans toutes mes relations, je suis plus vivante et plus
						active.
					</>
				}
			/>

			<BannerImageText pageId={pageId} entityId={6}></BannerImageText>

			<Footer />
		</div>
	)
}

export default TrainingLifeCoachingFirstModule
