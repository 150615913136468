import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'

import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import ImageTextContainer from '../../../../components/ImageTextContainer'
import ImageText from '../../../../components/ImageTextContainer/ImageText'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'
import Button from '../../../../components/Button'

function TrainerMindfulnessCoaching() {
	const pageId = 26

	return (
		<div className="SOUKIASSIAN-TrainerMindfulnessCoaching">
			<Header type="finalPage" title="FORMATION DE FORMATEUR DE COACH" />

			<div className="col-max-blocked center margined-y">
				<Button link="/trainer-trainings">
					<FontAwesomeIcon icon={faReply} />
					{' REVENIR AUX FORMATIONS DE SUPERVISEUR ET FORMATEURS'}
				</Button>
			</div>

			<ImageTextContainer>
				<ImageText
					pageId={pageId}
					entityId={1}
					flippedImage
					text={
						<>
							<span>
								L'école L.C.C. © propose une formation
								novatrice de formateur de{' '}
								<b>coach pleine conscience.</b>
							</span>
							<span>
								Vous apprenez à développer vos savoir-faire et
								savoir-être de formateur de coachs.
							</span>
							<span>
								Ouverte aux coachs, superviseurs, formateurs de
								l'école L.C.C. ©, cette formation de formateur
								vous apporte des connaissances de transmetteur
								en développement professionnel.
							</span>
							<span>
								Formez ceux qui vont participer au changement de
								l'humanité.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={2}
					text={
						<>
							<span>
								La formation comprend{' '}
								<b>
									les connaissances essentielles de formateur
									de coachs.
								</b>
							</span>
							<span>
								<b>Ses clés</b> enseignent le rôle et les
								positionnements du formateur en formation des
								coachs dans la réalisation de leurs
								accompagnements en séances individuelles et
								collectives.
							</span>
						</>
					}
					left
				/>
				<ImageText
					pageId={pageId}
					entityId={3}
					flippedImage
					text={
						<>
							<span>
								Les modules de notre formation s'articulent
								efficacement autour d'une pédagogie intuitive.
							</span>
							<span>
								Nos cours, 30% théorique et 70% pratique,
								offrent une expérience d'apprentissage complète,
								motivante, riche et harmonieuse.
							</span>
							<span>
								Le participant développe son potentiel de
								formateur pour enseigner l'art du coaching
								pleine conscience.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={4}
					text={
						<>
							<span>
								<b>Formation en groupe</b>
								<br />3 jours
								<br />
								555 €
							</span>
							<span>
								<b>Formation en individuel</b>
								<br />3 demi-journées
								<br />
								705 €
							</span>
							<span className="textCenter">
								<hr />
								<Button link="/contact">
									Contactez-nous pour un entretien
								</Button>
							</span>
						</>
					}
					left
				/>
			</ImageTextContainer>

			<BannerTestimonie
				pageId={pageId}
				entityId={5}
				name="Julien Edwin"
				commentary={
					<>
						La formation m'a réconforté dans mes apprentissages pour
						accompagner les futurs coachs en pleine conscience avec
						les codes éthiques de l'école LCC.
						<br />
						<br />
						Ça y est je forme des coachs qui lancent leur activité,
						superbe travail de professionnel. Merci à toute l'équipe
						de l'école LCC.
					</>
				}
			/>

			<BannerImageText pageId={pageId} entityId={6}></BannerImageText>

			<Footer />
		</div>
	)
}

export default TrainerMindfulnessCoaching
