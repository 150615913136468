import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'

import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import ImageTextContainer from '../../../../components/ImageTextContainer'
import ImageText from '../../../../components/ImageTextContainer/ImageText'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'
import Button from '../../../../components/Button'

function TrainerSupervisor() {
	const pageId = 24

	return (
		<div className="SOUKIASSIAN-TrainerSupervisor">
			<Header
				type="finalPage"
				title="FORMATION DE CONSULTANT SUPERVISEUR"
			/>

			<div className="col-max-blocked center margined-y">
				<Button link="/trainer-trainings">
					<FontAwesomeIcon icon={faReply} />
					{' REVENIR AUX FORMATIONS DE SUPERVISEUR ET FORMATEURS'}
				</Button>
			</div>

			<ImageTextContainer>
				<ImageText
					pageId={pageId}
					entityId={1}
					flippedImage
					text={
						<>
							<span>
								L'école L.C.C. © propose une formation de
								consultant superviseur des consultants
								spécialistes en relation d'aide, unique en
								France.
							</span>
							<span>
								Vous apprenez à développer vos savoir-faire et
								savoir-être en supervision.
							</span>
							<span>
								Ouverte à tous les consultants de la relation
								d'aide, cette formation vous apporte des
								compétences de superviseur.
							</span>
							<span>
								Transmettez les graines de l'avenir aux
								nouvelles générations.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={2}
					text={
						<>
							<span>
								La formation comprend{' '}
								<b>les clés essentielles de la supervision.</b>
							</span>
							<span>
								<b>Les premières clés</b> enseignent comment
								superviser le consultant dans son équilibre et
								sa réussite de vie.
							</span>
							<span>
								<b>Les autres clés</b> enseignent comment
								superviser le consultant dans ses divers
								positionnements d'aidant.
							</span>
						</>
					}
					left
				/>
				<ImageText
					pageId={pageId}
					entityId={3}
					text={
						<>
							<span>
								Les modules de notre formation s'articulent
								harmonieusement autour d'une pédagogie
								professionnelle, intelligente.
							</span>
							<span>
								Nos cours, 30% théorique et 70% pratique,
								offrent une expérience d'apprentissage complète
								et efficace.
							</span>
							<span>
								Le participant développe son potentiel de
								superviseur pour accompagner la stabilité du
								praticien dans ses pratiques.
							</span>
							<span>
								Participez au développement des aidants.
							</span>
						</>
					}
				/>
				<ImageText
					text={
						<>
							<span>
								<b>Formation en groupe</b>
								<br />3 jours
								<br />
								555 €
							</span>
							<span>
								<b>Formation en individuel</b>
								<br />3 demi-journées
								<br />
								705 €
							</span>
							<span className="textCenter">
								<hr />
								<Button link="/contact">
									Contactez-nous pour un entretien
								</Button>
							</span>
						</>
					}
					left
				/>
			</ImageTextContainer>

			<BannerTestimonie
				pageId={pageId}
				entityId={4}
				name="Natija"
				commentary={
					<>
						C'est tellement bon d'accompagner les spécialistes de la
						relation d'aide dans leur développement quand on sait
						qu'ils vont aider d'autres personnes, quelle richesse.
						<br />
						Je progresse tout le temps dans mon épanouissement
						professionnel.
					</>
				}
			/>

			<BannerImageText pageId={pageId} entityId={5}></BannerImageText>

			<Footer />
		</div>
	)
}

export default TrainerSupervisor
