import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-regular-svg-icons'

import Header from '../../../components/Header'
import Footer from '../../../components/Footer'
import ImageTextContainer from '../../../components/ImageTextContainer'
import ImageText from '../../../components/ImageTextContainer/ImageText'
import BannerTestimonie from '../../../components/BannerTestimonie'
import BannerImageText from '../../../components/BannerImageText'

function Christophe() {
	const pageId = 8

	return (
		<div className="SOUKIASSIAN-Christophe">
			<Header
				type="finalPage"
				title="LE FONDATEUR CHRISTOPHE SOUKIASSIAN"
			/>

			<ImageTextContainer>
				<ImageText
					pageId={pageId}
					entityId={1}
					flippedImage
					title="Chercheur émérite, Christophe Soukiassian"
					text={
						<>
							<span>
								est reconnu pour sa maîtrise de la communication
								consciente. Apprécié pour son approche naturelle
								et humaine, il se distingue par sa capacité
								d'interprétation et de transformation des états
								inconscients, grâce à son ressenti hors du
								commun.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={2}
					flippedImage
					title="Connaissances et compétences"
					text={
						<>
							<span>
								<b>Certifié</b> Coach et formateur de coach.
								<br />
								<b>Maîtrise</b> en gestion des états, dans
								l'habitat de la vie personnelle et
								professionnelle.
								<br />
								<b>Maîtrise</b> en gestion corporelle ”Canada”.
								<br />
								<b>Maîtrise</b> en P.N.L. avec expertise Europe
								<br />
								<b>Maîtrise</b> en communication avec les états
								de conscience modifiés “Monroe, US”.
								<br />
								<b>Expérimenté</b> en gestalt thérapie et
								analyse transactionnelle.
							</span>
						</>
					}
					left
				/>
				<ImageText
					pageId={pageId}
					entityId={3}
					title="Philanthrope et auteur prolifique de 30 ouvrages sur..."
					text={
						<>
							<span>
								<FontAwesomeIcon icon={faStar} /> La
								Communication non blessante
								<br />
								<FontAwesomeIcon icon={faStar} /> La
								Communication consciente
								<br />
								<FontAwesomeIcon icon={faStar} /> Le Coaching
								pleine conscience
							</span>
							<span>
								Coach consultant depuis 2007, formateur et
								fondateur, spécialisé en communication Non
								Blessante, en communication consciente et en
								coaching pleine conscience. Expert humaniste
								hors pair de la pleine conscience des états
								inconscients.
							</span>
						</>
					}
				/>
				<ImageText
					text={
						<>
							<span>
								<b>
									Les accompagnements diversifiés de
									Christophe Soukiassian
								</b>
								<br />
								Sollicité par de nombreuses personnes, jeunes et
								adultes, Christophe Soukiassian a accompagné
								divers profils, notamment :
								<br />
								- des artistes, chanteurs, athlètes olympiques,
								<br />
								- divers intervenants à la défense des
								territoires et citoyens français,
								<br />
								- des hommes politiques, médecins sans
								frontière, chercheurs, scientifiques,
								<br />- des responsables de centre de thérapies
								holistiques.
							</span>
							<span>
								<b>
									Les divers engagements de Christophe
									Soukiassian
								</b>
								<br />
								Passionné par des domaines variés tels que
								l'éducation, la recherche et les projets
								humanitaires, Christophe Soukiassian a également
								contribué à l'éducation des jeunes femmes du
								Mékong, à l'octroi de logements d'urgence pour
								les sans-abri et à l'aide alimentaire pour les
								personnes en difficulté.
							</span>
							<span>
								<b>
									Sa présence médiatique et ses intérêts pour
									les conflits interpersonnels
								</b>
								<br />
								Sollicité à plusieurs reprises par les médias
								français et canadiens, il concentre aujourd'hui
								un intérêt particulier sur les pays dont la
								population nécessite de résoudre des conflits
								relationnels résultant d'événements passés.
							</span>
						</>
					}
				/>
			</ImageTextContainer>

			<BannerTestimonie
				pageId={pageId}
				entityId={4}
				name="Philippe et Catherine Desbois"
				commentary={
					<>
						Christophe Soukiassian est une personne bienveillante,
						il a su nous montrer comment réussir en conscience. Nous
						nous sommes senti mieux pour agir avec l'intelligence
						intuitive LCC.
						<br />
						<br />
						Nous avons ainsi concrétisé plus facilement nos
						objectifs pour finaliser pleinement nos projets.
						<br />
						<br />
						Merci Christophe
						<br />
						pour tes accompagnements humanistes de qualité qui nous
						ont aidés dans nos accomplissements.
					</>
				}
			/>

			<BannerImageText
				pageId={pageId}
				entityId={5}
				text={
					<>
						“Christophe Soukiassian aide en conscience le
						développement des qualités, capacités et compétences
						humaines, il favorise ainsi l'accès au bien-être et à
						l'épanouissement dans tous les domaines de vie”
						<br />
						<br />
						-Christophe Soukiassian-
					</>
				}
			></BannerImageText>

			<Footer />
		</div>
	)
}

export default Christophe
