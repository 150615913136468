import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'

import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import ImageTextContainer from '../../../../components/ImageTextContainer'
import ImageText from '../../../../components/ImageTextContainer/ImageText'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'
import Button from '../../../../components/Button'

function Sagacity() {
	const pageId = 20

	return (
		<div className="SOUKIASSIAN-Sagacity">
			<Header
				type="finalPage"
				title="STAGE SAGESSE"
				subTitle="Transformez votre vie avec la sagesse L.C.C. ©"
			/>

			<div className="col-max-blocked center margined-y">
				<Button link="/stages">
					<FontAwesomeIcon icon={faReply} />
					{' REVENIR AUX STAGES'}
				</Button>
			</div>

			<ImageTextContainer>
				<ImageText
					pageId={pageId}
					entityId={1}
					flippedImage
					title="DÉCOUVERTE DE LA SAGESSE"
					text={
						<>
							<span>
								Accomplissez votre quête vers la sagesse !
							</span>
							<span>
								Faisons ensemble une rencontre passionnante de
								l'éveil.
							</span>
							<span>
								À travers une communication nourrissante, entrez
								dans une expérience rayonnante.
							</span>
							<span>
								<br />
								<b>Nous rejoindre</b>
								<br />
								<br />
								dans une immersion profonde avec les incroyables
								connaissances illimitées de l'esprit.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={2}
					flippedImage
					title={
						<>
							(1 journée)
							<br />
							Guidé par Christophe Soukiassian
						</>
					}
					text={
						<>
							<span>
								<b>L'éveil de la sagesse</b>
								<br />
								“Explorez votre sagesse.
								<br />
								Osez communiquer avec sagesse.”
							</span>
							<span>
								<b>Communication éveillée en binôme</b>
								<br />
								Les participants découvrent et développent leur
								propre sagesse dans leur communication.
							</span>
							<span>
								<b>Plongez au cœur de votre être</b>
								<br />
								Tissez des liens précieux dans un cadre
								bienveillant.
							</span>
						</>
					}
					left
				/>
				<ImageText
					pageId={pageId}
					entityId={3}
					title={
						<>
							Nous rejoindre dans ce stage
							<br />
							“Sagesse”
						</>
					}
					textCenter
					text={
						<span>
							Une journée en groupe
							<br />
							Tarification : 185€ / personne
							<br />
							<br />
							<Button link="/registration/stage/sagacity">
								CONTACT ET INSCRIPTION
							</Button>
						</span>
					}
				/>
			</ImageTextContainer>

			<BannerTestimonie
				pageId={pageId}
				entityId={4}
				name="Julien Gracia"
				commentary="Le stage Sagesse m'a offert une tranquillité révélatrice et inspirante. Ensemble, nous avons vécu une expérience précieuse de sérénité, guidée par la sagesse."
			/>

			<BannerImageText
				pageId={pageId}
				entityId={5}
				text={
					<>
						<span>STAGES</span>
						<br />
						Lyon et autres villes de France
					</>
				}
			></BannerImageText>

			<Footer />
		</div>
	)
}

export default Sagacity
