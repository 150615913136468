import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import ImageTextContainer from '../../../../components/ImageTextContainer'
import ImageText from '../../../../components/ImageTextContainer/ImageText'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'
import Button from '../../../../components/Button'
import Img from '../../../../components/Img'

import SessionBusinessCoachingDoc from "../../../../assets/documents/Session/Brochure_Coaching-d'entreprise.pdf"

function SessionBusinessCoaching() {
	const pageId = 14

	return (
		<div className="SOUKIASSIAN-SessionBusinessCoaching">
			<Header
				type="finalPage"
				title="BOOSTEZ VOTRE CARRIÈRE MAINTENANT"
			/>

			<ImageTextContainer>
				<ImageText
					pageId={pageId}
					entityId={1}
					text={
						<>
							<span>
								L'école L.C.C. © propose des séances
								individuelles innovantes pour salarié, manager,
								directeur et P.D.G.
							</span>
							<span>
								Vous{' '}
								<span className="underline">
									développez votre potentiel véritable
								</span>{' '}
								et{' '}
								<span className="underline">
									accédez à de meilleures compétences.
								</span>
							</span>
							<span>
								Accessibles à tous, les séances vous donnent{' '}
								<span className="underline">
									les solutions pour travailler avec
									efficience.
								</span>
							</span>
							<span>
								Développez{' '}
								<span className="underline">
									vos savoirs-faire professionnels.
								</span>
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={2}
					text={
						<>
							<span>
								Le coach accompagne tous les objectifs, projets
								et challenges de la vie professionnelle.
							</span>
							<span>
								Il est certifié avec plus de 15 ans d'expertise
								dans ses pratiques de coaching d'entreprise.
							</span>
						</>
					}
					left
				/>
				<ImageText
					pageId={pageId}
					entityId={3}
					flippedImage
					text={
						<>
							<span>
								Les séances de coaching s'articulent
								harmonieusement autour d'une relation
								intelligente, intuitive et consciente.
							</span>
							<span>
								Nos accompagnements développent des qualités
								humaines uniques pour la gestion des relations
								et du stress au travail.
							</span>
							<span className="underline">
								Bénéficiez de la technologie évolutive la plus
								avancée en coaching professionnel.
							</span>
						</>
					}
				/>
				<ImageText
					text={
						<>
							<span>
								Séance 1h par téléphone ou visio conférence
								<br />
								Tarification : sur mesure suivant statue
								professionnel et objectif
							</span>
							<span className="textCenter">
								<hr />
								<Img
									style={{ width: '85%', maxWidth: '300px' }}
									pageId={pageId}
									entityId={4}
									noInfoData
								/>
								<br />
								<Button
									target="_blank"
									link={SessionBusinessCoachingDoc}
								>
									Visualiser la brochure d'information
								</Button>
								<br />
								<Button link="/contact">
									Demander un rendez-vous
								</Button>
							</span>
						</>
					}
					left
				/>
			</ImageTextContainer>

			<BannerTestimonie
				pageId={pageId}
				entityId={5}
				name="Kevin Dimontel"
				commentary={
					<>
						Pour la gestion des pressions, les séances en coaching
						d'entreprise sont géniales.
						<br />
						Pour agir avec bienveillance en relation avec les
						autres, c'est carrément fabuleux.
						<br />
						<br />
						Alors allez-y si vous voulez être bien tout en étant
						productif dans votre travail et utiliser toutes vos
						stratégies personnelles, même celles les plus cachées au
						plus profond de vous-même (inconscientes).
					</>
				}
			/>

			<BannerImageText pageId={pageId} entityId={6}></BannerImageText>

			<Footer />
		</div>
	)
}

export default SessionBusinessCoaching
