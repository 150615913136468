import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'

import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import ImageTextContainer from '../../../../components/ImageTextContainer'
import ImageText from '../../../../components/ImageTextContainer/ImageText'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'
import Button from '../../../../components/Button'

function TrainerBusinessCoaching() {
	const pageId = 27

	return (
		<div className="SOUKIASSIAN-TrainerBusinessCoaching">
			<Header
				type="finalPage"
				title="FORMATION DE FORMATEUR EN ENTREPRISE"
			/>

			<div className="col-max-blocked center margined-y">
				<Button link="/trainer-trainings">
					<FontAwesomeIcon icon={faReply} />
					{' REVENIR AUX FORMATIONS DE SUPERVISEUR ET FORMATEURS'}
				</Button>
			</div>

			<ImageTextContainer>
				<ImageText
					pageId={pageId}
					entityId={1}
					text={
						<>
							<span>
								L'école L.C.C. © propose une formation
								efficiente de formateur en communication
								d'Écoute et d'Expression Non Blessantes.
							</span>
							<span>
								Vous apprenez à développer vos savoir-faire et
								savoir-être de formateur en coaching au travail.
							</span>
							<span>
								Ouverte aux coachs, superviseurs, formateurs de
								l'école L.C.C, cette formation vous apporte des
								compétences professionnelles en communication de
								salariés, managers, dirigeants...
							</span>
							<span>
								Participez aux compétences relationnelles des
								entreprises
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={2}
					text={
						<>
							<span>
								La formation comprend{' '}
								<b>
									les clés essentielles de la communication
									bienveillante en entreprise.
								</b>
							</span>
							<span>
								<b>Ses clés</b> apprennent le rôle et les
								positionnements du formateur en formation dans
								les entreprises pour faire évoluer une
								communication proactive bienveillante.
							</span>
						</>
					}
					left
				/>
				<ImageText
					pageId={pageId}
					entityId={3}
					flippedImage
					text={
						<>
							<span>
								Les journées de notre formation comprennent une
								pédagogie professionnelle, intelligente.
							</span>
							<span>
								Nos cours, 30% théorique et 70% pratique,
								offrent une expérience d'apprentissage complète
								et efficiente.
							</span>
							<span>
								Le participant développe ses compétences de
								formateur en communication d'Écoute et
								d'Expression Non Blessante.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={4}
					flippedImage
					text={
						<>
							<span>
								<b>Formation en groupe</b>
								<br />3 jours
								<br />
								555 €
							</span>
							<span>
								<b>Formation en individuel</b>
								<br />3 demi-journées
								<br />
								705 €
							</span>
							<span className="textCenter">
								<hr />
								<Button link="/contact">
									Contactez-nous pour un entretien
								</Button>
							</span>
						</>
					}
					left
				/>
			</ImageTextContainer>

			<BannerTestimonie
				pageId={pageId}
				entityId={5}
				flippedImage
				name="Marina Courion"
				commentary={
					<>
						Je suis enfin arrivé à être formatrice en communication
						d'écoute et d'expression non blessantes.
						<br />
						<br />
						C'est lucratif d'atteindre tous ses buts et de
						bénéficier d'une notoriété à l'aide d'un concept
						exceptionnel en communication.
						<br />
						<br />
						Je booste tant le personnel d'entreprise en formation de
						groupe que les managers et dirigeants en formation
						individuelle.
					</>
				}
			/>

			<BannerImageText pageId={pageId} entityId={6}></BannerImageText>

			<Footer />
		</div>
	)
}

export default TrainerBusinessCoaching
