import { useLoaderData, useOutlet } from 'react-router-dom'

import PropTypes from 'prop-types'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import RegistrationList from '../../components/RegistrationList/'
import RegistrationCategoryList from '../../components/RegistrationList/Category'

import './index.scss'

function Registration({ isListRegistration, isIdRegistration }) {
	const outlet = useOutlet()
	const data = useLoaderData()
	const pageId = 29

	return (
		<>
			{outlet || (
				<div className="SOUKIASSIAN-Registration">
					<Header type="finalPage" title="S'INSCRIRE" />

					{isListRegistration || isIdRegistration ? (
						<RegistrationList data={data} />
					) : (
						<RegistrationCategoryList />
					)}

					<Footer />
				</div>
			)}
		</>
	)
}

Registration.propTypes = {
	isListRegistration: PropTypes.bool.isRequired,
	isIdRegistration: PropTypes.bool.isRequired,
}

Registration.defaultProps = {
	isListRegistration: false,
	isIdRegistration: false,
}

export default Registration
