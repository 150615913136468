import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'

import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import ImageTextContainer from '../../../../components/ImageTextContainer'
import ImageText from '../../../../components/ImageTextContainer/ImageText'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'
import Button from '../../../../components/Button'

function TrainerConsciousCommunication() {
	const pageId = 25

	return (
		<div className="SOUKIASSIAN-TrainerConsciousCommunication">
			<Header
				type="finalPage"
				title="FORMATION DE FORMATEUR EN COMMUNICATION CONSCIENTE"
			/>

			<div className="col-max-blocked center margined-y">
				<Button link="/trainer-trainings">
					<FontAwesomeIcon icon={faReply} />
					{' REVENIR AUX FORMATIONS DE SUPERVISEUR ET FORMATEURS'}
				</Button>
			</div>

			<ImageTextContainer>
				<ImageText
					pageId={pageId}
					entityId={1}
					text={
						<>
							<span>
								L'école L.C.C. © propose une formation inédite
								de formateur en communication consciente.
							</span>
							<span>
								Vous apprenez à développer vos savoir-faire et
								savoir-être de formateur en développement
								personnel, familial, social et professionnel.
							</span>
							<span>
								Ouverte aux coachs, superviseurs de l'école
								L.C.C. ©, cette formation de formateur vous
								apporte des compétences exceptionnelles en
								communication humaine.
							</span>
							<span>
								Exercer un métier passionnant, transmettez un
								savoir unique.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={2}
					text={
						<>
							<span>
								La formation comprend{' '}
								<b>
									les clés essentielles de l'enseignement du
									développement personnel.
								</b>
							</span>
							<span>
								<b>Ses clés</b> apprennent au futur formateur
								comment se positionner dans l'enseignement des
								interprétations transformatrices des états
								inconscients par l'Écoute et l'Expression Non
								blessantes.
							</span>
						</>
					}
					left
				/>
				<ImageText
					pageId={pageId}
					entityId={3}
					flippedImage
					text={
						<>
							<span>
								Les modules de notre formation s'articulent
								intelligemment autour d'une pédagogie intuitive,
								harmonieuse.
							</span>
							<span>
								Nos cours, 30% théorique et 70% pratique,
								offrent une expérience d'apprentissage
								novatrice, complète et professionnelle.
							</span>
							<span>
								Le participant développe son potentiel de
								formateur pour former dans la bienveillance.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={4}
					text={
						<>
							<span>
								<b>Formation en groupe</b>
								<br />3 jours
								<br />
								555 €
							</span>
							<span>
								<b>Formation en individuel</b>
								<br />3 demi-journées
								<br />
								705 €
							</span>
							<span className="textCenter">
								<hr />
								<Button link="/contact">
									Contactez-nous pour un entretien
								</Button>
							</span>
						</>
					}
					left
				/>
			</ImageTextContainer>

			<BannerTestimonie
				pageId={pageId}
				entityId={5}
				name="Florence Achille"
				commentary={
					<>
						Je n'aurais pas cru arrivait jusque-là. Quel bonheur,
						quelle joie intense que j'éprouve à animer des petits
						groupes de deux à quatre personnes avec régularité dans
						la formation de communication consciente. Je suis
						heureuse.
						<br />
						Merci Christophe pour cette belle formation si complète.
					</>
				}
			/>

			<BannerImageText
				pageId={pageId}
				entityId={6}
				flipped
			></BannerImageText>

			<Footer />
		</div>
	)
}

export default TrainerConsciousCommunication
