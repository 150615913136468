import Header from '../../../components/Header'
import Footer from '../../../components/Footer'
import ImageTextContainer from '../../../components/ImageTextContainer'
import ImageText from '../../../components/ImageTextContainer/ImageText'
import BannerTestimonie from '../../../components/BannerTestimonie'
import BannerImageText from '../../../components/BannerImageText'

function LccSchool() {
	const pageId = 6

	return (
		<div className="SOUKIASSIAN-LccSchool">
			<Header
				type="finalPage"
				title="LA PRÉSENTATION DE L'ÉCOLE L.C.C. ©"
			/>

			<ImageTextContainer>
				<ImageText
					pageId={pageId}
					entityId={1}
					title="Fondée en 2009 à Paris par Christophe Soukiassian"
					text={
						<>
							<span>
								<b>
									l'école L.C.C. © est passée numéro 1 en
									France
								</b>{' '}
								de la communication consciente et du coaching
								pleine conscience, elle se distingue pas son
								approche innovante.
							</span>
							<span>
								<b>Depuis 2014, l'école L.C.C. ©</b> n'a cessé
								d'évoluer et de perfectionner ses trois concepts
								phares en communication. Grâce à sa méthode
								unique en développement du potentiel humain,
								elle accompagne des individus et des
								organisations de partout, offrant des outils et
								des techniques exceptionnels pour maximiser les
								dynamiques d'évolution.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={2}
					title="L'école L.C.C. © excelle aujourd'hui"
					text={
						<>
							<span>
								Elle se différencie par sa communication
								novatrice d'interprétation et de transformation
								positives des états inconscients.
							</span>
							<span>
								<b>L'école L.C.C. © propose</b>
								<br />
								des stages, des formations, ainsi que des
								séances individuelles et collectives, destinés à
								tous :
								<br />
								particuliers, spécialistes de la relation d'aide
								et entreprises.
							</span>
						</>
					}
					left
				/>
				<ImageText
					pageId={pageId}
					entityId={3}
					title="A l'École L.C.C. ©"
					text={
						<>
							<span>
								Les participants apprennent à améliorer leur vie
								personnelle, conjugale, familiale, sociale et
								professionnelle.
								<br />
								Ils deviennent des coachs exceptionnels.
								<br />
								Les entreprises, bénéficient de relations
								bienveillantes favorisant un travail harmonieux
								et proactif.
							</span>
						</>
					}
				/>
			</ImageTextContainer>

			<BannerTestimonie
				pageId={pageId}
				entityId={4}
				name="Marc Duchemin"
				commentary={
					<>
						Je suis très heureux de mon parcours à l'école LCC.
						Grâce à leurs stages, formations et séances, j'ai pu
						atteindre mes objectifs et réaliser mes projets, ce qui
						est merveilleux.
						<br />
						<br />
						Je communique désormais de manière harmonieuse et je
						suis conscient de mes changements positifs.
						<br />
						Aujourd'hui, je gère bien mieux ma vie personnelle et
						professionnelle.
					</>
				}
			/>

			<BannerImageText
				pageId={pageId}
				entityId={5}
				text={
					<>
						“Surmonter les blocages avec la gestion des états
						inconscients de l'école L.C.C. ©, conduit à la réussite
						dans tous les domaines”
						<br />
						<br />
						-Christophe Soukiassian-
					</>
				}
			></BannerImageText>

			<Footer />
		</div>
	)
}

export default LccSchool
