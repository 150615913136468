import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import ImageTextContainer from '../../../../components/ImageTextContainer'
import ImageText from '../../../../components/ImageTextContainer/ImageText'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'
import Button from '../../../../components/Button'
import Img from '../../../../components/Img'

import TrainingLifeCoachingDoc from '../../../../assets/documents/Training/Brochure-formation_Coacher-sa-vie.pdf'

function TrainingLifeCoaching() {
	const pageId = 9

	return (
		<div className="SOUKIASSIAN-TrainingLifeCoaching">
			<Header type="finalPage" title="APPRENEZ À ACCOMPAGNER LA VIE" />

			<ImageTextContainer>
				<ImageText
					pageId={pageId}
					entityId={1}
					text={
						<>
							<span>
								Avec la formation de communication consciente :
								Apprenez à communiquer en conscience. Maîtrisez
								vos échanges et transformez votre vie.
								Accompagnez la résolution de problématiques, la
								concrétisation d’objectifs, la réalisation de
								projets et l’épanouissement de vie avec la
								révélation du potentiel véritable.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={2}
					text={
						<>
							<span>
								Le formateur vous transmet les savoir-faire de
								la communication consciente, avec plus de 18
								années de formation auprès de personnes avec
								divers modes de vie personnel, familial, social
								et professionnel.
							</span>
						</>
					}
					left
				/>
				<ImageText
					pageId={pageId}
					entityId={3}
					flippedImage
					text={
						<>
							<span>
								Apprenez à installer un équilibre dans tous les
								domaines de la vie avec une communication
								consciente unique et accompagnez dans la
								bienveillance, le bien-être, la réussite de vie
								et l’épanouissement des autres.
							</span>
						</>
					}
				/>
				<ImageText
					text={
						<>
							<span>
								<b>Formation en groupe</b>
								<br />
								18 jours non-consécutifs.
								<br />
								185 € la journée par personne
							</span>
							<span>
								<b>Formation en individuel</b>
								<br />
								18 demi-journées non-consécutives.
								<br />
								235 € la demi-journée par personne
							</span>
							<span className="textCenter">
								<hr />
								<Img
									style={{ width: '85%', maxWidth: '300px' }}
									pageId={pageId}
									entityId={4}
									noInfoData
								/>
								<br />
								<Button
									target="_blank"
									link={TrainingLifeCoachingDoc}
								>
									Télécharger le programme
								</Button>
								<br />
								<Button link="/contact">S'INSCRIRE</Button>
							</span>
						</>
					}
					left
				/>
			</ImageTextContainer>

			<BannerTestimonie
				pageId={pageId}
				entityId={5}
				name="Chantal Lagneau"
				commentary={
					<>
						Avec la communication consciente, j'ai découvert et
						affirmé ma véritable identité.
						<br />
						<br />
						<b>Je gère désormais ma vie</b> en pleine conscience,
						harmonisant chaque jour mes émotions en gérant mes
						pensées, paroles et actes. Je vis désormais une relation
						épanouissante avec moi-même, en corrélation avec les
						autres et le monde.
					</>
				}
			/>
			{/* <BannerTestimonie
				pageId={pageId}
				entityId={5}//285
				name="Renaud"
				commentary={
					<>
						J'ai vraiment éprouvé beaucoup de plaisirs à entrer en
						formation pour y découvrir une méthode qui déchire dans
						le sens où j'ai pu me retrouver avec moi et les autres
						en sachant ce que je voulais accomplir dans ma vie,
						merci à tout le groupe, quelle belle formation
					</>
				}
			/> */}

			<BannerImageText pageId={pageId} entityId={6}></BannerImageText>

			<Footer />
		</div>
	)
}

export default TrainingLifeCoaching
