import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import ImageTextContainer from '../../../../components/ImageTextContainer'
import ImageText from '../../../../components/ImageTextContainer/ImageText'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'
import Button from '../../../../components/Button'
import Img from '../../../../components/Img'

import TrainingBecomeCoachDoc from '../../../../assets/documents/Training/Brochure-formation_Devenir-coach.pdf'

function TrainingBecomeCoach() {
	const pageId = 10

	return (
		<div className="SOUKIASSIAN-TrainingBecomeCoach">
			<Header type="finalPage" title="APPRENEZ À INSPIRER ET GUIDER" />

			<ImageTextContainer>
				<ImageText
					pageId={pageId}
					entityId={1}
					flippedImage
					text={
						<>
							<span>
								Avec la formation de coach pleine conscience :
								Communiquez dans la bienveillance, harmonisez
								les relations d’aide et apportez des solutions
								aux problèmes, accompagnez la concrétisation des
								objectifs, la réalisation des projets,
								l’épanouissement personnel et la révélation du
								véritable potentiel.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={2}
					text={
						<>
							<span>
								En tant que coach certifié L.C.C. ©, vous
								guiderez jeunes et adultes, en séances
								individuelles et collectives, dans tous les
								aspects de leur vie : personnel, conjugal,
								familial, social et professionnel. Fort de vos
								expertises et de votre expérience issue de vos
								échanges quotidiens, vous deviendrez un atout
								essentiel pour leur développement.
							</span>
						</>
					}
					left
				/>
				<ImageText
					pageId={pageId}
					entityId={3}
					flippedImage
					text={
						<>
							<span>
								Devenez un coach exceptionnel grâce à cette
								méthode de coaching innovante, conçue par
								Christophe Soukiassian, qui s'appuie sur
								l'apprentissage à travers votre propre
								communication et vos expériences personnelles,
								en harmonie avec votre histoire de vie. Cultivez
								une humanité authentique et bâtissez une
								renommée remarquable.
							</span>
						</>
					}
				/>
				<ImageText
					text={
						<>
							<span>
								<b>Formation en groupe</b>
								<br />
								12 jours non-consécutifs.
								<br />
								185 € la journée par personne
							</span>
							<span>
								<b>Formation en individuel</b>
								<br />
								12 demi-journées non-consécutives.
								<br />
								235 € la demi-journée par personne
							</span>
							<span className="textCenter">
								<hr />
								<Img
									style={{ width: '85%', maxWidth: '300px' }}
									pageId={pageId}
									entityId={4}
									noInfoData
								/>
								<br />
								<Button
									target="_blank"
									link={TrainingBecomeCoachDoc}
								>
									Télécharger le programme
								</Button>
								<br />
								<Button link="/contact">S'INSCRIRE</Button>
							</span>
						</>
					}
					left
				/>
			</ImageTextContainer>

			{/* <BannerTestimonie
				pageId={pageId}
				entityId={5}//283
				name="Christine"
				commentary={
					<>
						Je suis coach maintenant et toutes mes méthodes
						précédentes, je les applique dans mes coaching ou plutôt
						je dirais que j'applique le coaching de partout avec mes
						méthodes et en plus je suis moi-même dans ma vie, ce qui
						me soutient tous les jours dans mes réussites
						quotidiennes.
					</>
				}
			/> */}
			<BannerTestimonie
				pageId={pageId}
				entityId={5}
				name="Lisa Soukiassian"
				commentary={
					<>
						Je suis <b>certifié coach</b> par l'école L.C.C. © Je
						m'épanouis dans cette profession.
						<br />
						<br />
						Mes compétences acquises me permettent d'accompagner
						dans l'authenticité les réalisations de vie. Je maîtrise
						mon destin guidant naturellement les autres vers leurs
						réussites dans tous les domaines.
					</>
				}
			/>

			<BannerImageText pageId={pageId} entityId={6}></BannerImageText>

			<Footer />
		</div>
	)
}

export default TrainingBecomeCoach
