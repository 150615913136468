import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import ImageTextContainer from '../../../../components/ImageTextContainer'
import ImageText from '../../../../components/ImageTextContainer/ImageText'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'
import Button from '../../../../components/Button'
import Img from '../../../../components/Img'

import SessionBecomeCoachDoc from '../../../../assets/documents/Session/Brochure_Devenir-coach.pdf'

function SessionBecomeCoach() {
	const pageId = 13

	return (
		<div className="SOUKIASSIAN-SessionBecomeCoach">
			<Header
				type="finalPage"
				title="DEVENEZ SPÉCIALISTE INSPIRANT EN RELATION D'AIDE"
			/>

			<ImageTextContainer>
				<ImageText
					pageId={pageId}
					entityId={1}
					flippedImage
					text={
						<>
							<span>
								Avec les séances individuelles de supervision
								des spécialistes de la relation d'aide, en
								coaching pleine conscience : Communiquez dans la
								bienveillance, harmonisez les relations d’aide
								et apportez des solutions aux problèmes,
								accompagnez plus facilement la concrétisation
								des objectifs, la réalisation des projets,
								l’épanouissement personnel et la révélation du
								véritable potentiel à chacun de vos accompagnés.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={2}
					flippedImage
					text={
						<>
							<span>
								En tant que spécialiste de la relation d'aide,
								vous guiderez jeunes et adultes, en séances
								individuelles et collectives, dans tous les
								aspects de leur vie : personnel, conjugal,
								familial, social et professionnel. Fort de vos
								changements adaptés à votre profession, ainsi
								que de vos nouvelles expériences issue de vos
								accompagnements quotidiens, vous deviendrez un
								atout essentiel pour le développement de vos
								clients.
							</span>
						</>
					}
					left
				/>
				<ImageText
					pageId={pageId}
					entityId={3}
					text={
						<>
							<span>
								Devenez spécialiste de la relation d'aide, grâce
								à la supervision innovante en coaching pleine
								conscience L.C.C. ©, conçue par Christophe
								Soukiassian, qui s'appuie sur l'évolution de
								votre communication dans vos expérience
								personnelles du quotidien, en harmonie avec
								votre histoire de vie. Cultivez une relation
								humaine authentique et bâtissez une renommée
								incomparable.
							</span>
						</>
					}
				/>
				<ImageText
					text={
						<>
							<span>
								Séance 3/4h par téléphone ou visio conférence
								<br />
								Tarification : 90€
							</span>
							<span className="textCenter">
								<hr />
								<Img
									style={{ width: '85%', maxWidth: '300px' }}
									pageId={pageId}
									entityId={4}
									noInfoData
								/>
								<br />
								<Button
									target="_blank"
									link={SessionBecomeCoachDoc}
								>
									Visualiser la brochure d'information
								</Button>
								<br />
								<Button link="/contact">
									Demander un rendez-vous
								</Button>
							</span>
						</>
					}
					left
				/>
			</ImageTextContainer>

			<BannerTestimonie
				pageId={pageId}
				entityId={5}
				name="Nadia Satin"
				commentary={
					<>
						Tout me va, alors qu'avant tout était flou. Je peux agir
						en m'épanouissant dans l'activité de mes rêves, la
						relation d'aide.
						<br />
						<br />
						Avec les supervisions de coaching L.C.C. tout a bien
						changé, moi, mon environnement et mon activité
						professionnelle. Dans ma relation avec mes clients, avec
						chaque nouvelle personne en séance, j'augmente la
						qualité de mes accompagnements. Quel bonheur !
					</>
				}
			/>

			<BannerImageText pageId={pageId} entityId={6}></BannerImageText>

			<Footer />
		</div>
	)
}

export default SessionBecomeCoach
