import { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import Img from '../../components/Img'
import ImageInfo from '../../components/ImageInfo'
import Stars from '../Stars'
import BubblePrice from '../BubblePrice'

import './index.scss'

function PictureTextLink({
	pageId,
	entityId,
	link,
	onClick,
	title,
	bordered,
	width,
	className,
	flipped,
	animate,
	stars,
	price,
	promo,
}) {
	const pictureElement = useRef()

	const classIsBordered = bordered
		? ' SOUKIASSIAN-PictureTextLink-bordered'
		: ''
	const classIsAnimate = animate
		? ' SOUKIASSIAN-PictureTextLink-container-animate'
		: ''

	useEffect(() => {
		if (typeof IntersectionObserver !== 'undefined') {
			const observer = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting) {
					pictureElement.current.classList.add(
						'SOUKIASSIAN-PictureTextLink-container-viewed',
					)
				} else {
					pictureElement.current.classList.remove(
						'SOUKIASSIAN-PictureTextLink-container-viewed',
					)
				}
			})

			observer.observe(pictureElement.current)

			return () => observer.disconnect()
		} else {
			pictureElement.current.classList.add(
				'SOUKIASSIAN-PictureTextLink-container-viewedIsNotAvailable',
			)
		}
	}, [])

	return (
		<div
			ref={pictureElement}
			className={`SOUKIASSIAN-PictureTextLink-container${classIsAnimate} ${className}`}
		>
			<div className={`SOUKIASSIAN-PictureTextLink${classIsBordered}`}>
				{(price !== 0 || promo !== 0) && (
					<BubblePrice price={price} promo={promo} />
				)}
				{link ? (
					<Link to={link} onClick={onClick}>
						<Img
							pageId={pageId}
							entityId={entityId}
							noInfoData
							flipped={flipped}
							style={{ width: width }}
						/>
						{stars !== 0 && <Stars count={stars} />}
						{title && <h3>{title}</h3>}
					</Link>
				) : (
					<>
						<Img
							pageId={pageId}
							entityId={entityId}
							flipped={flipped}
							style={{ width: width }}
							onClick={onClick}
						/>
						{stars !== 0 && <Stars count={stars} />}
						{title && <h3>{title}</h3>}
					</>
				)}
				<ImageInfo imgData="GET" pageId={pageId} entityId={entityId} />
			</div>
		</div>
	)
}

PictureTextLink.propTypes = {
	pageId: PropTypes.number.isRequired,
	entityId: PropTypes.number.isRequired,
	link: PropTypes.string,
	onClick: PropTypes.func,
	title: PropTypes.string,
	bordered: PropTypes.bool,
	width: PropTypes.string,
	className: PropTypes.string,
	flipped: PropTypes.bool,
	animate: PropTypes.bool,
	stars: PropTypes.number,
	price: PropTypes.number,
	promo: PropTypes.number,
}

PictureTextLink.defaultProps = {
	bordered: false,
	className: '',
	flipped: false,
	animate: false,
	stars: 0,
	price: 0,
	promo: 0,
}

export default PictureTextLink
