import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import ImageTextContainer from '../../../../components/ImageTextContainer'
import ImageText from '../../../../components/ImageTextContainer/ImageText'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'
import Button from '../../../../components/Button'
import Img from '../../../../components/Img'

import SessionLifeCoachingDoc from '../../../../assets/documents/Session/Brochure_Coacher-sa-vie.pdf'

function SessionLifeCoaching() {
	const pageId = 12

	return (
		<div className="SOUKIASSIAN-SessionLifeCoaching">
			<Header type="finalPage" title="PRENEZ LES RÊNES DE VOTRE VIE" />

			<ImageTextContainer>
				<ImageText
					pageId={pageId}
					entityId={1}
					text={
						<>
							<span>
								Avec les séances individuelles de communication
								consciente : solutionnez vos problèmes,
								harmonisez vos relations, communiquez dans la
								bienveillance, concrétisez vos objectifs,
								réalisez vos projets, épanouissez votre vie,
								révélez votre potentiel véritable.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={2}
					flippedImage
					text={
						<>
							<span>
								Le coach de vie vous accompagne en séances
								individuelles de communication consciente, avec
								plus de 18 années d’expertise auprès de
								nombreuses personnes, d’âges, de branches
								sociales et de cultures diverses.
							</span>
						</>
					}
					left
				/>
				<ImageText
					pageId={pageId}
					entityId={3}
					flippedImage
					text={
						<>
							<span>
								Reprenez le contrôle de votre vie avec un
								accompagnement unique en communication
								consciente et obtenez, en confiance, de la
								clarté sur les directions à prendre,
								libérez-vous de vos émotions pour une pleine
								autonomie.
							</span>
						</>
					}
				/>
				<ImageText
					text={
						<>
							<span>
								Séance 3/4h par téléphone ou visio conférence
								<br />
								Tarification : 65€
							</span>
							<span className="textCenter">
								<hr />
								<Img
									style={{ width: '85%', maxWidth: '300px' }}
									pageId={pageId}
									entityId={4}
									noInfoData
								/>
								<br />
								<Button
									target="_blank"
									link={SessionLifeCoachingDoc}
								>
									Visualiser la brochure d'information
								</Button>
								<br />
								<Button link="/contact">
									Demander un rendez-vous
								</Button>
							</span>
						</>
					}
					left
				/>
			</ImageTextContainer>

			<BannerTestimonie
				pageId={pageId}
				entityId={5}
				name="Maeva"
				commentary={
					<>
						Ouf, ma vie était très agitée et maintenant tout
						s'enclenche correctement avec patience. Je réalise mes
						souhaits comme je l'entends et en plus je découvre
						comment le développement de mon potentiel humain m'amène
						à faire les choses plus facilement tous les jours,
						merci, merci, merci, Christophe.
					</>
				}
			/>

			<BannerImageText pageId={pageId} entityId={6}></BannerImageText>

			<Footer />
		</div>
	)
}

export default SessionLifeCoaching
