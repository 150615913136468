import Header from '../../../components/Header'
import BannerText from '../../../components/BannerText'
import HarmoniousTitle from '../../../components/HarmoniousTitle'
import Footer from '../../../components/Footer'
import ImageTextFilletPowder from '../../../components/ImageTextFilletPowder'
import ITFPChild from '../../../components/ImageTextFilletPowder/ITFPChild'

import './index.scss'

function Stages() {
	const pageId = 4

	return (
		<div className="SOUKIASSIAN-Stages">
			<Header
				type="intermediatePage"
				title="ÉLEVEZ VOTRE CONSCIENCE"
				pageId={pageId}
				entityId={1}
			/>

			<BannerText
				title={
					<>
						ÉVOLUONS ENSEMBLE
						<br />
						Lors de cinq stages exceptionnels.
					</>
				}
			/>

			<HarmoniousTitle title="LES STAGES D'ORIENTATION DE BIEN-ÊTRE ET BIEN-VIVRE">
				<ImageTextFilletPowder>
					<ITFPChild
						title="INITIATION CONSCIENCE"
						pageId={pageId}
						entityId={2}
						stars={1}
						price={20}
						link="consciousness-initiation"
						text="Initiez-vous à la conscience de soi lors d'échanges interactifs avec un groupe d'amis. Approfondissez votre compréhension de vous-même, des autres et de la vie grâce à des révélations puissantes. En seulement une demi-journée, explorez de nouvelles perspectives et faites des découvertes personnelles enrichissantes."
						animate
					/>
					<ITFPChild
						title="SAGESSE"
						pageId={pageId}
						entityId={3}
						stars={2}
						promo={50}
						flippedImage
						link="sagacity"
						text="Découvrez une communication plus humaine, à intégrer au quotidien pour des relations authentiques et bienveillantes. En une journée, apprenez à cultiver des échanges sincères et constructifs."
						animate
						right
					/>
					<ITFPChild
						title="MÉDITATION"
						pageId={pageId}
						entityId={4}
						stars={3}
						flippedImage
						link="meditation"
						text="Connectez-vous à votre environnement et apprenez à vivre en pleine conscience de vos états internes. En une journée, découvrez des techniques de méditation simples et naturelles pour gérer vos émotions rapidement et cultiver une attitude plus positive."
						animate
					/>
					<ITFPChild
						title="ÉLÉVATION CONSCIENCE"
						pageId={pageId}
						entityId={5}
						stars={4}
						promo={50}
						link="foreign-trip"
						text="Profitez de trois journées pour vivre des révélations transformatrices afin de vous ouvrir à de nouveaux horizons, en élevant votre conscience."
						animate
						right
					/>
					<ITFPChild
						title="NOURRIR SA VIE"
						pageId={pageId}
						entityId={6}
						stars={5}
						link="eat-well"
						text="Apprenez à orienter positivement vos pensées, paroles et actions en harmonie avec vos aspirations profondes. En deux journées de jeûne, découvrez comment cultiver un équilibre intérieur et une stabilité durable pour nourrir pleinement votre vie."
						animate
					/>
				</ImageTextFilletPowder>
			</HarmoniousTitle>

			<Footer />
		</div>
	)
}

export default Stages
