import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

import './index.scss'

function Stars({ count }) {
	return (
		<>
			{count === 1 && (
				<FontAwesomeIcon
					className="SOUKIASSIAN-Stars-icon SOUKIASSIAN-Stars-icon1"
					icon={faStar}
				/>
			)}
			{count === 2 && (
				<>
					<FontAwesomeIcon
						className="SOUKIASSIAN-Stars-icon SOUKIASSIAN-Stars-icon1"
						icon={faStar}
					/>
					<FontAwesomeIcon
						className="SOUKIASSIAN-Stars-icon SOUKIASSIAN-Stars-icon2"
						icon={faStar}
					/>
				</>
			)}
			{count === 3 && (
				<>
					<FontAwesomeIcon
						className="SOUKIASSIAN-Stars-icon SOUKIASSIAN-Stars-icon1"
						icon={faStar}
					/>
					<FontAwesomeIcon
						className="SOUKIASSIAN-Stars-icon SOUKIASSIAN-Stars-icon2"
						icon={faStar}
					/>
					<FontAwesomeIcon
						className="SOUKIASSIAN-Stars-icon SOUKIASSIAN-Stars-icon3"
						icon={faStar}
					/>
				</>
			)}
			{count === 4 && (
				<>
					<FontAwesomeIcon
						className="SOUKIASSIAN-Stars-icon SOUKIASSIAN-Stars-icon1"
						icon={faStar}
					/>
					<FontAwesomeIcon
						className="SOUKIASSIAN-Stars-icon SOUKIASSIAN-Stars-icon2"
						icon={faStar}
					/>
					<FontAwesomeIcon
						className="SOUKIASSIAN-Stars-icon SOUKIASSIAN-Stars-icon3"
						icon={faStar}
					/>
					<FontAwesomeIcon
						className="SOUKIASSIAN-Stars-icon SOUKIASSIAN-Stars-icon4"
						icon={faStar}
					/>
				</>
			)}
			{count === 5 && (
				<>
					<FontAwesomeIcon
						className="SOUKIASSIAN-Stars-icon SOUKIASSIAN-Stars-icon1"
						icon={faStar}
					/>
					<FontAwesomeIcon
						className="SOUKIASSIAN-Stars-icon SOUKIASSIAN-Stars-icon2"
						icon={faStar}
					/>
					<FontAwesomeIcon
						className="SOUKIASSIAN-Stars-icon SOUKIASSIAN-Stars-icon3"
						icon={faStar}
					/>
					<FontAwesomeIcon
						className="SOUKIASSIAN-Stars-icon SOUKIASSIAN-Stars-icon4"
						icon={faStar}
					/>
					<FontAwesomeIcon
						className="SOUKIASSIAN-Stars-icon SOUKIASSIAN-Stars-icon5"
						icon={faStar}
					/>
				</>
			)}
		</>
	)
}

Stars.propTypes = {
	count: PropTypes.number,
}

Stars.defaultProps = {
	count: 0,
}

export default Stars
