import Header from '../../../components/Header'
import HarmoniousTitle from '../../../components/HarmoniousTitle'
import PictureTextLink from '../../../components/PictureTextLink'
import Footer from '../../../components/Footer'
import BannerTestimonie from '../../../components/BannerTestimonie'

function BusinessCoaching() {
	const pageId = 35

	return (
		<div className="SOUKIASSIAN-BusinessCoaching">
			<Header type="finalPage" title="COACHING D'ENTREPRISE" />
			<HarmoniousTitle
				className="SOUKIASSIAN-Home-HarmoniousTitle SOUKIASSIAN-Home-HarmoniousTitle-trainerTrainings"
				title="SÉANCE"
				subTitle="COACHING PROFESSIONNEL"
			>
				<PictureTextLink
					className="SOUKIASSIAN-Home-PictureTextLink"
					pageId={pageId}
					entityId={1}
					link="/session-business-coaching"
					width="calc(100% + 26px)"
					animate
				/>
			</HarmoniousTitle>
			<HarmoniousTitle
				className="SOUKIASSIAN-Home-HarmoniousTitle SOUKIASSIAN-Home-HarmoniousTitle-trainerTrainings"
				title="FORMATION"
				subTitle="COMMUNICATION D'ÉCOUTE ET D'EXPRESSION NON BLESSANTES"
			>
				<PictureTextLink
					className="SOUKIASSIAN-Home-PictureTextLink"
					pageId={pageId}
					entityId={2}
					link="/training-business-coaching"
					width="calc(100% + 26px)"
					animate
				/>
			</HarmoniousTitle>
			{/* <BannerTestimonie
				pageId={pageId}
				entityId={3}
				name="Christophe Champion"
				commentary={
					<>
						Grâce aux outils de communication de Christophe
						Soukiassian, je gère le stress professionnel avec
						succès, atteignant mes objectifs plus facilement.
						<br />
						<br />
						Son coaching aide à surmonter les obstacles, contribuant
						ainsi à la <b>croissance des entreprises</b> et au
						développement des professionnels.
					</>
				}
			/> */}
			<Footer />
		</div>
	)
}

export default BusinessCoaching
