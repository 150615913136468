import PropTypes from 'prop-types'

import './index.scss'

function BubblePrice({ price, promo }) {
	return (
		<div className="SOUKIASSIAN-BubblePrice-container">
			<span className="SOUKIASSIAN-BubblePrice">
				{price !== 0 && `${price}€`}
				{promo !== 0 && `-${promo}%`}
			</span>
		</div>
	)
}

BubblePrice.propTypes = {
	price: PropTypes.number,
	promo: PropTypes.number,
}

BubblePrice.defaultProps = {
	price: 0,
	promo: 0,
}

export default BubblePrice
