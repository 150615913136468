import Header from '../../../components/Header'
import BannerText from '../../../components/BannerText'
import HarmoniousTitle from '../../../components/HarmoniousTitle'
import Footer from '../../../components/Footer'
import ImageTextFilletPowder from '../../../components/ImageTextFilletPowder'
import ITFPChild from '../../../components/ImageTextFilletPowder/ITFPChild'

import './index.scss'

function Trainings() {
	const pageId = 5

	return (
		<div className="SOUKIASSIAN-Trainings">
			<Header
				type="intermediatePage"
				title="TRANSMETTEZ VOS SAVOIRS"
				pageId={pageId}
				entityId={1}
				imgTop
			/>

			<BannerText
				title={
					<>
						RÉUSSISSONS ENSEMBLE
						<br />
						Lors de nouvelles dimensions de superviseur et
						formateurs.
					</>
				}
			/>

			<HarmoniousTitle
				title=""
				subTitle="(Ces formations son accessibles au coach L.C.C. © après leurs certifications de communicateur conscient et de coach pleine conscience)"
			>
				<ImageTextFilletPowder>
					<ITFPChild
						title="Superviseur"
						pageId={pageId}
						entityId={2}
						stars={2}
						link="supervisor"
						text={
							<>
								Développez des connaissances de{' '}
								<b>superviseur</b> et guidez les spécialistes en
								relation d'aide dans leur bien-être et
								réalisation, personnels et professionnels.
							</>
						}
						animate
					/>
					<ITFPChild
						title="Communication Consciente"
						pageId={pageId}
						entityId={3}
						stars={3}
						flippedImage
						link="conscious-communication"
						text={
							<>
								Développez des connaissances de{' '}
								<b>formateur en communication consciente</b> et
								formez tout public en individuel et en groupe.
							</>
						}
						animate
						right
					/>
					<ITFPChild
						title="Coaching Pleine Conscience"
						pageId={pageId}
						entityId={4}
						stars={4}
						link="mindfulness-coaching"
						text={
							<>
								Développez des capacités de{' '}
								<b>formateur en coaching pleine conscience</b>{' '}
								et formez des groupes avec efficacité.
							</>
						}
						animate
					/>
					<ITFPChild
						title="Coaching d'Entreprise"
						pageId={pageId}
						entityId={5}
						stars={5}
						flippedImage
						link="business-coaching"
						text={
							<>
								Développez des compétences de{' '}
								<b>formateur en communication Non Blessante</b>{' '}
								et formez en entreprise les salariés, managers
								et dirigeants.
							</>
						}
						animate
						right
					/>
				</ImageTextFilletPowder>
			</HarmoniousTitle>

			<Footer />
		</div>
	)
}

export default Trainings
