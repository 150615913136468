import Header from '../../components/Header'
import BannerText from '../../components/BannerText'
import BannerThreePicturesLink from '../../components/BannerThreePicturesLink'
import HarmoniousTitle from '../../components/HarmoniousTitle'
import Footer from '../../components/Footer'

import './index.scss'

function Home() {
	const pageId = 2

	return (
		<div className="SOUKIASSIAN-Home">
			<Header
				type="home"
				pageId={pageId}
				entityId={1}
				title="Découvrez une séance offerte !"
				subTitle={
					<>
						<br />
						Osez une vie authentique.
						<br />
						Transformez vos difficultés.
						<br />
						Brillez en pleine conscience.
					</>
				}
				/*buttonText="NOUS REJOINDRE LORS D'UN ÉVÉNEMENT"*/
				buttonText="CONTACTEZ-NOUS"
			/>

			<BannerText
				className="SOUKIASSIAN-Home-headerSubTitle"
				title={
					<>
						<span>Développez le potentiel véritable</span>
					</>
				}
				textNoBg
			>
				Cette année, reconnectons-nous à la véritable essence de l'être
				humain.
				<br />
				<br />
				Avançons ensemble, avec bienveillance, vers la réalisation de
				vos projets personnels et professionnels.
				<br />
				<br />
				Construisons un quotidien harmonieux et aidons les autres à en
				faire de même.
			</BannerText>

			<HarmoniousTitle
				className="SOUKIASSIAN-Home-HarmoniousTitle"
				title="LES STAGES, SÉANCES ET FORMATIONS POUR TOUS"
			>
				<BannerThreePicturesLink
					picture1={{
						title: 'COACHER SA VIE',
						pageId: pageId,
						entityId: 2,
						flipped: true,
						link: '/life-coaching',
					}}
					picture2={{
						title: 'DEVENIR COACH',
						pageId: pageId,
						entityId: 3,
						link: '/become-coach',
					}}
					picture3={{
						title: "COACHING D'ENTREPRISE",
						pageId: pageId,
						entityId: 4,
						link: '/business-coaching',
						flipped: true,
					}}
					bordered
				/>
			</HarmoniousTitle>

			<BannerText>
				<b>Découvrez de nouvelles perspectives</b> et explorez vos
				potentiels grâce à la méthode L.C.C. © créée par Christophe
				Soukiassian.
				<br />
				<br />À travers les accompagnements de l'école,{' '}
				<b>vous serez guidé</b> pour progresser et combler l'écart entre
				votre situation actuelle et vos aspirations.
			</BannerText>

			{/* <TestimoniesHome
				pageId={pageId}
				entityId={6}
				entityIdList={[7, 8, 9]}
			/> */}

			<Footer />
		</div>
	)
}

export default Home
