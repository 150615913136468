import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'

import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import ImageTextContainer from '../../../../components/ImageTextContainer'
import ImageText from '../../../../components/ImageTextContainer/ImageText'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'
import Button from '../../../../components/Button'

import './index.scss'

function ForeignTrip() {
	const pageId = 23

	return (
		<div className="SOUKIASSIAN-ForeignTrip">
			<Header
				type="finalPage"
				title="STAGE ÉLÉVATION CONSCIENCE"
				subTitle="Gérer l'évolution de votre vie avec l'élévation de conscience L.C.C. ©"
			/>

			<div className="col-max-blocked center margined-y">
				<Button link="/stages">
					<FontAwesomeIcon icon={faReply} />
					{' REVENIR AUX STAGES'}
				</Button>
			</div>

			<ImageTextContainer>
				<ImageText
					pageId={pageId}
					entityId={1}
					title="VOYAGE INTÉRIEUR"
					text={
						<>
							<span>Élevez votre conscience !</span>
							<span>
								Venez avec nous pour incarner un parcours de
								développement personnel captivant et lumineux.
							</span>
							<span>
								Plongeons ensemble dans les profondeurs de notre
								être et explorons l'essence même de notre
								humanité.
							</span>
							<span>
								<br />
								<b>Rejoignez-nous</b>
								<br />
								<br />
								pour découvrir votre plein potentiel et cultiver
								une vie en évolution multidimensionnelle.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={2}
					title={
						<>
							(3 journées)
							<br />
							Accompagné par Christophe Soukiassian
						</>
					}
					text={
						<>
							<span>
								“Explorez de nouveaux horizons.
								<br />
								Osez vous rencontrer véritablement.”
							</span>
							<span>
								<b>Le Voyage Intérieur</b>
								<br />
								Les participants franchissent les frontières de
								leur humanité, voyageant au cœur de leur âme
								incarnée.
							</span>
							<span>
								<b>Soyez en Osmose</b>
								<br />
								Découvrez les merveilles insoupçonnées, cachées
								au plus profond de votre existence.
							</span>
						</>
					}
					left
				/>
				<ImageText
					pageId={pageId}
					entityId={3}
					flippedImage
					title={
						<>
							Nous rejoindre dans ce stage
							<br />
							“Élévation conscience”
						</>
					}
					textCenter
					text={
						<span>
							Trois journées en groupe
							<br />
							Tarification : 185€ / personne / jour
							<br />
							<br />
							<Button link="/registration/stage/foreign-trip">
								CONTACT ET INSCRIPTION
							</Button>
						</span>
					}
				/>
			</ImageTextContainer>

			<BannerTestimonie
				pageId={pageId}
				entityId={4}
				name="Mohammed Doucemar"
				commentary={
					<>
						Ce voyage m'a permis de me reconnecter profondément avec
						moi-même, loin des distractions de la vie quotidienne.
						J'ai vécu trois journées transcendantes qui m'ont
						ramenées à l'essence de qui je suis vraiment.
					</>
				}
			/>

			<BannerImageText pageId={pageId} entityId={5}></BannerImageText>

			<Footer />
		</div>
	)
}

export default ForeignTrip
