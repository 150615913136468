// import { useSelector } from 'react-redux'

import HarmoniousTitle from '../../components/HarmoniousTitle'
import PictureTextLink from '../../components/PictureTextLink'

import './index.scss'

function RegistrationCategoryList() {
	// const isConnectedUser = useSelector(
	// 	(state) => state.userReducer.isConnected,
	// )
	const pageId = 29

	return (
		<div className="SOUKIASSIAN-RegistrationList-Category">
			<HarmoniousTitle
				className="col-xl-max-blocked"
				title="ÉVÉNEMENTS"
				sticky
			>
				<div className="SOUKIASSIAN-RegistrationList-Category-pictureList">
					<PictureTextLink
						link="event/webinars"
						pageId={pageId}
						entityId={1}
						title="WEBINAIRE"
					/>
				</div>
			</HarmoniousTitle>

			<HarmoniousTitle
				className="col-xl-max-blocked"
				title="STAGES"
				sticky
			>
				<div className="SOUKIASSIAN-RegistrationList-Category-pictureList">
					<PictureTextLink
						className="col-xxs-12 col-xs-12 col-5"
						link="stage/consciousness-initiation"
						pageId={pageId}
						entityId={2}
						title="INITIATION CONSCIENCE"
					/>

					<PictureTextLink
						className="col-xxs-12 col-xs-12 col-5"
						link="stage/sagacity"
						pageId={pageId}
						entityId={3}
						title="SAGESSE"
					/>
					<PictureTextLink
						className="col-xxs-12 col-xs-12 col-5"
						link="stage/meditation"
						pageId={pageId}
						entityId={4}
						title="MÉDITATION"
						flipped
					/>
					<PictureTextLink
						className="col-xxs-12 col-xs-12 col-5"
						link="stage/foreign-trip"
						pageId={pageId}
						entityId={5}
						title="ÉLÉVATION CONSCIENCE"
					/>
					<PictureTextLink
						className="col-xxs-12 col-xs-12 col-5"
						link="stage/eat-well"
						pageId={pageId}
						entityId={6}
						title="BIEN SE NOURRIR"
					/>
				</div>
			</HarmoniousTitle>

			<HarmoniousTitle
				className="col-xl-max-blocked"
				title="FORMATION<br>COMMUNICATION CONSCIENTE L.C.C. ©"
			>
				<div className="SOUKIASSIAN-RegistrationList-Category-pictureList">
					<PictureTextLink
						link="training/training-life-coaching"
						pageId={pageId}
						entityId={7}
						title="COACHER SA VIE"
					/>
				</div>
			</HarmoniousTitle>

			<HarmoniousTitle
				className="col-xl-max-blocked"
				title="FORMATION<br>COACH PLEINE CONSCIENCE L.C.C. ©"
			>
				<div className="SOUKIASSIAN-RegistrationList-Category-pictureList">
					<PictureTextLink
						link="training/training-become-coach"
						pageId={pageId}
						entityId={8}
						title="DEVENIR COACH"
					/>
				</div>
			</HarmoniousTitle>
			<HarmoniousTitle
				className="col-xl-max-blocked"
				title="FORMATION DE FORMATEUR"
			>
				<div className="SOUKIASSIAN-RegistrationList-Category-pictureList">
					<PictureTextLink
						link="training/trainer-trainings"
						pageId={pageId}
						entityId={9}
						title="DEVENEZ FORMATEUR"
					/>
				</div>
			</HarmoniousTitle>
		</div>
	)
}

export default RegistrationCategoryList
