import Header from '../../../components/Header'
import HarmoniousTitle from '../../../components/HarmoniousTitle'
import PictureTextLink from '../../../components/PictureTextLink'
import Footer from '../../../components/Footer'

function BecomeCoach() {
	const pageId = 34

	return (
		<div className="SOUKIASSIAN-BecomeCoach">
			<Header type="finalPage" title="DEVENIR COACH" />
			<HarmoniousTitle
				className="SOUKIASSIAN-Home-HarmoniousTitle SOUKIASSIAN-Home-HarmoniousTitle-trainerTrainings"
				title="SÉANCE"
				subTitle="COACHING PLEINE CONSCIENCE"
			>
				<PictureTextLink
					className="SOUKIASSIAN-Home-PictureTextLink"
					pageId={pageId}
					entityId={1}
					link="/session-become-coach"
					width="calc(100% + 26px)"
					animate
				/>
			</HarmoniousTitle>
			<HarmoniousTitle
				className="SOUKIASSIAN-Home-HarmoniousTitle SOUKIASSIAN-Home-HarmoniousTitle-trainerTrainings"
				title="FORMATION"
				subTitle="COACH PLEINE CONSCIENCE"
			>
				<PictureTextLink
					className="SOUKIASSIAN-Home-PictureTextLink"
					pageId={pageId}
					entityId={2}
					link="/training-become-coach"
					width="calc(100% + 26px)"
					animate
				/>
			</HarmoniousTitle>
			<HarmoniousTitle
				className="SOUKIASSIAN-Home-HarmoniousTitle SOUKIASSIAN-Home-HarmoniousTitle-trainerTrainings"
				title="LES FORMATIONS DE FORMATEURS"
			>
				<PictureTextLink
					className="SOUKIASSIAN-Home-PictureTextLink"
					title="DEVENEZ FORMATEUR"
					pageId={pageId}
					entityId={3}
					link="/trainer-trainings"
					bordered
					width="calc(100% + 26px)"
					animate
				/>
			</HarmoniousTitle>
			<Footer />
		</div>
	)
}

export default BecomeCoach
